import axios from 'axios';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';
import moment from 'moment';

export const sendWelcomeEmail = (email, name) => {
  axios.post('https://eof8x5y3avaii7r.m.pipedream.net', {
    email,
    name,
  })
  .then(() => {
    console.log('Workflow triggered successfully.');
  })
  .catch((error) => {
    console.error('Error triggering workflow:', error);
  });
};

export const sendNewRegistrationEmail = (company, address, city, state, zip, firstname, lastname, mobilephone, email) => {

  const emailSubject = `New ISO Registered - Action Required`;
  const emailContent = `The following company has just enrolled in the Quidity Business Funding portal:\nCompany Name: ${company}\nAddress: ${address}\nCity: ${city}\nState: ${state}\nZip: ${zip}\nPrimary Contact Person: ${firstname} ${lastname}\nTelephone: ${mobilephone}\nContact Email: ${email}\nDate: ${moment().format('MM/DD/YYYY')}\n\nPlease contact them at your earliest convenience to walk them through the process of submitting deals for your programs.\n\nBest regards,\nThe Quidity Business Funding Team `;

  axios.post('https://eowhg0qaeoinlv5.m.pipedream.net', {
    emailSubject,
    emailContent,
  })
  .then(() => {
    console.log('Workflow triggered successfully.');
  })
  .catch((error) => {
    console.error('Error triggering workflow:', error);
  });
};

export const sendStatusChangeEmail = (referrer, referrerEmail, newStatus, newStatusDescription, businessName) => {
  const emailSubject = `Status Update - ${businessName}`;
  const referrerName = referrer.split(' ')[0];

  // Trigger the Pipedream workflow without waiting for the response
  axios.post('https://eoihzjlkp7u3agl.m.pipedream.net', {
    email: referrerEmail,
    emailSubject,
    referrerName,
    status: newStatus,
    description: newStatusDescription,
  })
  .then(() => {
    console.log('Workflow triggered successfully.');
  })
  .catch((error) => {
    console.error('Error triggering workflow:', error);
  });

  // Continue with other tasks immediately
  console.log('Continuing execution without waiting for the email response.');
};


export const fetchUserInfo = async (user) => {
  try {
    const userRef = doc(db, 'users', user); 
    
    // Reference to the user document in Firestore
    const docSnap = await getDoc(userRef);

    if (docSnap.exists()) {
      return (docSnap.data()); // Set the user info state with data from Firestore
    } else {
      console.log('No such document!');
      return null
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
  }
};