import React from 'react';
import { useLocation } from 'wouter';

const Footer = ({ user }) => {
  const [location] = useLocation()

  return (
    (['/portal', '/create-deal'].includes(location) && user) &&
    <footer className="mt-[200px] bg-gray-200 text-gray-700 p-4 text-sm border-t-[8px] border-themeColorPrimary">
      <div className="max-w-6xl mx-auto">
        <div className="mb-6">
          <p className="mt-4 text-center">
          ALL APPLICATIONS ARE SUBJECT TO UNDERWRITING AND CREDIT APPROVAL.
          <br />
          TERMS AND CONDITIONS MAY VARY BASED ON INDIVIDUAL BUSINESS QUALIFICATIONS AND THE LENDER’S DISCRETION.
          </p>
        </div>
        
        <div className="mt-4 mb-6 font-bold">
          <p>For questions on your partner agreement or commissions, <br />
            please contact: <a href="mailto:aj@quidity.net" className="text-themeColorPrimary hover:underline">aj@quidity.net</a>
          </p>
        </div>
        <div className="text-center mt-2 md:mt-6 font-bold">
          <p>Quidity© 2024 All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
