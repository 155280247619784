import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';
import { useLocation, useParams } from 'wouter';

const initialStyles = {
  businessName: {},
  dba: {},
  referrer: {},
  referrerEmail: {},
  useOfFunds: {},
  address: {},
  city: {},
  state: {},
  zip: {},
  tel: {},
  dateIncorporated: {},
  industry: {},
  businessDescription: {},
  ownerName: {},
  ownerTel: {},
  ownerEmail: {},
  ownerDOB: {},
  ownerSSN: {},
  pctOwnership: {},
  driversLicenseFront: {},
  driversLicenseBack: {},
  bankStatement1: {},
  bankStatement2: {},
  bankStatement3: {},
  voidedCheck: {},
  businessApplication: {},
};

const validStyle = { outline: '2px solid #078586' };
const invalidStyle = { outline: '2px solid red' };

const ModifyDeals = ({ user, userInfo }) => {

  const [dealData, setDealData] = useState({});
  const params = useParams();

  const [formData, setFormData] = useState({
    referrer: '',
    referrerEmail: '',
    useOfFunds: '',
    businessName: '',
    dba: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    tel: '',
    dateIncorporated: '',
    industry: '',
    businessDescription: '',
    ownerName: '',
    ownerTel: '',
    ownerEmail: '',
    ownerDOB: '',
    ownerSSN: '',
    pctOwnership: '',
    driversLicenseFront: '',
    hasLargeLenderBalances: '',
    bankStatement1: '',
    bankStatement2: '',
    bankStatement3: '',
    voidedCheck: '',
    businessApplication: '',
    agreedToTerms: false,
  });

  useEffect(() => {
    const dealRef = doc(db, 'deals', params.id);

    const resolve = async () => {
      const deal = (await getDoc(dealRef)).data();
      setDealData(deal);
    };

    resolve();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFormData({
      referrer: dealData.referrer,
      referrerEmail: dealData.referrerEmail,
      useOfFunds: dealData.useOfFunds,
      businessName: dealData.businessName,
      dba: dealData.dba,
      address: dealData.address,
      city: dealData.city,
      state: dealData.state,
      zip: dealData.zip,
      tel: dealData.tel,
      dateIncorporated: dealData.dateIncorporated,
      industry: dealData.industry,
      businessDescription: dealData.businessDescription,
      ownerName: dealData.ownerName,
      ownerTel: dealData.ownerTel,
      ownerEmail: dealData.ownerEmail,
      ownerDOB: dealData.ownerDOB,
      ownerSSN: dealData.ownerSSN,
      pctOwnership: dealData.pctOwnership,
      driversLicenseFront: dealData.driversLicenseFront,
      hasLargeLenderBalances: dealData.hasLargeLenderBalances,
      bankStatement1: dealData.bankStatement1,
      bankStatement2: dealData.bankStatement2,
      bankStatement3: dealData.bankStatement3,
      voidedCheck: dealData.voidedCheck,
      businessApplication: dealData.businessApplication,
      agreedToTerms: dealData.agreedToTerms,
    });
  // eslint-disable-next-line
  }, [dealData]);
  
  const [loading, setLoading] = useState(false);
  const [submitStatusMessage, setSubmitStatusMessage] = useState('');
  // eslint-disable-next-line
  const [location, navigate] = useLocation();

  const industryCategories = {
    'Agriculture': ['None of the below', 'Non-profit'],
    'Automotive Repair': ['Body repair, collision, painting', 'General repair, truck, rv', 'Parts, accessories', 'Quick service lube, tune-up', 'Tire, glass', 'Transmission', 'Car wash', 'Upholstery', 'Non-profit'],
    'Automobile Sales & Gas Services Stations': ['None of the below', 'Automotive dealerships new/used', 'Gas station/Convenience', 'New & Used car dealers', 'RV dealers', 'Non-profit'],
    'Business Services': ['None of the below', 'Advertising sales', 'Appraisal services', 'Billing services', 'Direct/Multi-level marketing', 'Mail center', 'Printing/graphic design', 'Staffing/temp agencies', 'Non-profit'],
    'Communications': ['None of the below', 'Non-profit'],
    'Construction & Contractors': ['None of the below', 'Construction contractors', 'Electricians', 'Flooring', 'Heating/Air conditioning/HVAC', 'House remodeling/improvements (ir. Structural remodeling)', 'Housing related', 'Painters', 'Plumbers', 'Roofing/siding', 'Tile/carpet', 'Windows', 'Non-profit'],
    'Eating & Drinking Places': ['None of the below', 'Bar', 'Catering', 'Ice cream shops', 'Restaurant - casual dining', 'Restaurant - fast food & carry out', 'Restaurant - fine dining', 'Non-profit'],
    'Educational Services': ['None of the below', 'Daycare', 'Schools', 'Schools - educational training certifications', 'Schools - private/elementary/high school', 'Seminars', 'Non-profit'],
    'Entertainment Services': ['None of the below', 'Adult entertainment', 'Art galleries', 'Casinos', 'Cruise ships', 'Entertainment venue', 'Event planning', 'Ticket brokers', 'Travel agencies', 'Non-profit'],
    'Finance': ['None of the below', 'Accounting', 'Bail bonds', 'Check cashing companies', 'Collection agencies', 'Consumer credit counseling services', 'Payday loan companies', 'Tax return preparation services', 'Non-profit'],
    'Food & Beverage Stores': ['None of the below', 'Food- bakery', 'Food - convenience & variety', 'Food - deli, meat, seafood, other', 'Food - grocery store', 'Liquor store', 'Non-profit'],
    'Freight': ['None of the below', 'Tow truck and yard', 'Tractor trailer transportation', 'Non-profit'],
    'Health Services': ['None of the below', 'Dental', 'Hair replacements/clubs', 'Health club', 'Health club monthly membership', 'Marijuana shop', 'Medical services', 'Pharmacy', 'Spa Weightless centers', 'Non-profit'],
    'Hotels & Lodging': ['None of the below', 'Hotel & motel', 'Timeshares', 'Non-profit'],
    'Insurance': ['None of the below', 'Insurance', 'Warranty companies', 'Non-profit'],
    'Legal Services': ['None of the below', 'Law firms', 'Professional service (All doctors, lawyers)', 'Non-profit'],
    'Manufacturing': ['None of the below', 'Non-profit'],
    'Mining & Natural Resource Extraction': ['None of the below', 'Non-profit'],
    'Miscellaneous Services': ['None of the below', 'Equipment rental', 'Guard dog service', 'Internet prescription', 'Other service retail', 'Non-profit'],
    'Personal Services': ['None of the below', 'Annual memberships', 'Dry cleaners', 'Hair & nail salon', 'Interior design', 'Lawn & garden', 'Maid & cleaning services', 'Online dating/memberships', 'Pet washing & grooming', 'Tanning & tattoo', 'Non-profit'],
    'Real Estate': ['None of the below', 'Property management/real estate'],
    'Religious Services': ['None of the below', 'Churches', 'Funeral homes', 'Non-profit'],
    'Retail': ['None of the below', 'Antiques/collectibles', 'Auctions houses', 'Bridal shop', 'Clothing & apparel', 'Computer-related retail', 'Ebay sales', 'Electronics', 'Firearms', 'Flea market', 'Florists', 'Furniture', 'Furriers', 'Golf- stores, driving ranges, country clubs', 'Heating oil', 'Home appliance stores', 'Home based door/door', 'Home entertainment systems', 'Internet sales (no storefront)', 'Internet sales (storefront)', 'Internet software downloads', 'Jewelry', 'Kiosks', 'Mattresses', 'Pet store', 'Pools/spas', 'Sporting goods', 'Tobacco shop', 'Wireless phone/accessories', 'Other product retail', 'Non-profit'],
    'Storage': ['None of the below', 'Boating/marina', 'Non-profit'],
    'Transportation': ['None of the below', 'Airlines', 'Daycare services - home based not allowed', 'Day tours', 'Limousine & transportation', 'Non-profit'],
    'Wholesale': ['None of the below', 'Import/export', 'Non-profit'],
    'Vehicle Rental': ['None of the below', 'Non-profit']
  };

  // eslint-disable-next-line
  const [categories, setCategories] = useState([]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
  
    // Check if it's a file input and handle the file properly
    if (files && files.length > 0) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: files[0],  // Store the actual File object in formData
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,  // Store other input values normally
      }));
    }
  };
  

  const areAllFieldsFilled = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(formData.ownerEmail) &&
    formData.businessName &&
    formData.referrer &&
    formData.referrerEmail &&
    formData.useOfFunds &&
    formData.address &&
    formData.city &&
    formData.state &&
    formData.zip &&
    formData.tel &&
    formData.dateIncorporated &&
    formData.industry &&
    formData.businessDescription &&
    formData.ownerName &&
    formData.ownerTel &&
    formData.ownerDOB &&
    formData.ownerSSN &&
    formData.pctOwnership &&
    formData.driversLicenseFront &&
    formData.hasLargeLenderBalances &&
    formData.bankStatement1 &&
    formData.bankStatement2 &&
    formData.bankStatement3;
  };

  const [fieldStyles, setFieldStyles] = useState(initialStyles);
  
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      if (!file || !(file instanceof Blob)) {
        // If the file is not provided or not a Blob, return 'N/A'
        resolve('N/A');
        return;
      }
  
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const submitForm = async () => {
  setSubmitStatusMessage('Submitting documents...');
  setLoading(true);

  try {
    const files = [
      formData.driversLicenseFront instanceof File
        ? { name: 'driversLicenseFront', content: await fileToBase64(formData.driversLicenseFront), type: formData.driversLicenseFront?.type }
        : formData.driversLicenseFront,  // Keep raw URL or existing value
      formData.bankStatement1 instanceof File
        ? { name: 'bankStatement1', content: await fileToBase64(formData.bankStatement1), type: formData.bankStatement1?.type }
        : formData.bankStatement1,  // Keep raw URL or existing value
      formData.bankStatement2 instanceof File
        ? { name: 'bankStatement2', content: await fileToBase64(formData.bankStatement2), type: formData.bankStatement2?.type }
        : formData.bankStatement2,  // Keep raw URL or existing value
      formData.bankStatement3 instanceof File
        ? { name: 'bankStatement3', content: await fileToBase64(formData.bankStatement3), type: formData.bankStatement3?.type }
        : formData.bankStatement3,  // Keep raw URL or existing value
      formData.businessApplication instanceof File
        ? { name: 'businessApplication', content: await fileToBase64(formData.businessApplication), type: formData.businessApplication?.type }
        : formData.businessApplication,  // Keep raw URL or existing value
      formData.voidedCheck instanceof File
        ? { name: 'voidedCheck', content: await fileToBase64(formData.voidedCheck), type: formData.voidedCheck?.type }
        : formData.voidedCheck,  // Keep raw URL or existing value
      formData.taxReturn1 instanceof File
        ? { name: 'taxReturn1', content: await fileToBase64(formData.taxReturn1), type: formData.taxReturn1?.type }
        : formData.taxReturn1,  // Keep raw URL or existing value
      formData.taxReturn2 instanceof File
        ? { name: 'taxReturn2', content: await fileToBase64(formData.taxReturn2), type: formData.taxReturn2?.type }
        : formData.taxReturn2,  // Keep raw URL or existing value
      formData.personalTaxReturn1 instanceof File
        ? { name: 'personalTaxReturn1', content: await fileToBase64(formData.personalTaxReturn1), type: formData.personalTaxReturn1?.type }
        : formData.personalTaxReturn1,  // Keep raw URL or existing value
      formData.microSbaApplication instanceof File
        ? { name: 'microSbaApplication', content: await fileToBase64(formData.microSbaApplication), type: formData.microSbaApplication?.type }
        : formData.microSbaApplication,  // Keep raw URL or existing value
      formData.quidityLibriumSBA instanceof File
        ? { name: 'quidityLibriumSBA', content: await fileToBase64(formData.quidityLibriumSBA), type: formData.quidityLibriumSBA?.type }
        : formData.quidityLibriumSBA  // Keep raw URL or existing value
    ]

    console.log(files);

    // Construct the payload with proper file handling
    const payload = {
      formData: {
        ...formData,
        driversLicenseFront: files[0],
        bankStatement1: files[1],
        bankStatement2: files[2],
        bankStatement3: files[3],
        businessApplication: files[4],
        voidedCheck: files[5],
        taxReturn1: files[6],
        taxReturn2: files[7],
        personalTaxReturn1: files[8],
        microSbaApplication: files[9],
        quidityLibriumSBA: files[10]
      },
      userId: user.uid,
      userEmail: user.email,
      userInfo,
      docId: params.id,
    };


    await axios.post('http://127.0.0.1:5001/quidity-app/us-central1/modifyDeal', payload);
    
    setSubmitStatusMessage('Deal updated successfully.');
    navigate('/portal'); // Redirect after success
  } catch (error) {
    console.error('Error updating deal:', error);
    setSubmitStatusMessage('Error updating deal.');
  } finally {
    setLoading(false);
  }
};


  const handleSubmit = (e) => {
    e.preventDefault();

    if(!areAllFieldsFilled()) {
      return;
    }

    return submitForm()
    .then(async({            
      driversLicenseFrontUrl,
      bankStatement1Url,
      bankStatement2Url,
      bankStatement3Url,
      voidedCheckUrl,
      businessApplicationUrl,
      taxReturn1Url,
      taxReturn2Url,
      personalTaxReturn1Url,
    }) => {

      setSubmitStatusMessage('Updating deal...');

      
      // Generate a new document reference with a random ID in the "deals" collection
      const dealRef = doc(db, 'deals', params.id);

      // Construct the new document data, including the userId field set to the user's email
      const updatedDeal = {
        ...dealData,
        ...formData,
        driversLicenseFront: driversLicenseFrontUrl || dealData.driversLicenseFront,
        bankStatement1: bankStatement1Url || dealData.bankStatement1,
        bankStatement2: bankStatement2Url || dealData.bankStatement2,
        bankStatement3: bankStatement3Url || dealData.bankStatement3,
        voidedCheck: voidedCheckUrl !== 'N/A' ? voidedCheckUrl : dealData.voidedCheck,
        taxReturn1: taxReturn1Url !== 'N/A' ? taxReturn1Url : dealData.taxReturn1,
        taxReturn2: taxReturn2Url !== 'N/A' ? taxReturn2Url : dealData.taxReturn2,
        personalTaxReturn1: personalTaxReturn1Url !== 'N/A' ? personalTaxReturn1Url : dealData.personalTaxReturn1,
        businessApplication: businessApplicationUrl || dealData.businessApplication,
        updatedAt: serverTimestamp(),
      };

      // Save the new deal document in Firestore
      await setDoc(dealRef, updatedDeal);
      
      setSubmitStatusMessage('Done...');
      setLoading(false);
      navigate('/portal');
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
  
    let isValid = false; // Assume validity to start
  
    switch (name) {
      case 'ownerEmail':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        isValid = emailRegex.test(value);
        break;
      case 'zip':
        isValid = value.length===5;
        break;
      case 'tel':
      case 'ownerTel':
        isValid = value.length===10;
        break;
      case 'businessName':
      case 'referrer':
      case 'referrerEmail':
      case 'useOfFunds':
      case 'address':
      case 'city':
      case 'state':
      case 'dateIncorporated':
      case 'industry':
      case 'businessDescription':
      case 'ownerName':
      case 'ownerDOB':
      case 'ownerSSN':
      case 'pctOwnership':
      case 'driversLicenseFront':
      case 'bankStatement1':
      case 'bankStatement2':
      case 'bankStatement3':
      case 'businessApplication':
        // For these fields, check that the value is not empty. If it is, set isValid to false.
        isValid = value.trim() !== '';
        break;
      default:
        // For any fields not explicitly handled, assume they are valid
        isValid = true;
    }
  
    setFieldStyles(prevStyles => ({
      ...prevStyles,
      [name]: isValid ? validStyle : invalidStyle
    }));
  };

  return (
    ((user?.email === dealData?.userId) || (user.email === 'admin@quidity.net')) &&
    <div className="bg-themeColorPrimary3 min-h-screen flex flex-col justify-center items-center" style={{ animation: 'bottom-top 500ms ease' }}>
      <div className="mt-[200px] md:mt-[150px] lg:mt-[150px] bg-white shadow-md rounded-xl px-8 pt-6 pb-8 w-[95%] md:w-[800px] lg:w-[800px] mb-[50px]">
        <h1 className="block w-full text-center text-grey-darker text-[28px] mb-6 font-bold text-themeColorPrimary2">Modify Deal</h1>
        <form onSubmit={handleSubmit}>
        <p className='text-lg font-medium mb-2'>Who should we contact regarding this merchant?</p>
        <div className='flex flex-col md:flex-row lg:flex-row w-full gap-0 md:gap-4 lg:gap-4'>
            {/* Referrer Field */}
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="address">
                Referrer *
              </label>
              <input
                style={fieldStyles.referrer}
                onBlur={handleBlur}
                type="text"
                id="referrer"
                name="referrer"
                value={formData.referrer}
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>
            {/* Referrer Field */}
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="address">
                Referrer Email *
              </label>
              <input
                style={fieldStyles.referrerEmail}
                onBlur={handleBlur}
                type="text"
                id="referrerEmail"
                name="referrerEmail"
                value={formData.referrerEmail}
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>
          </div>

          {/* Fields like in RegistrationPage */}
          <div className='flex flex-col md:flex-row lg:flex-row w-full gap-0 md:gap-4 lg:gap-4'>
            {/* Business Name Field */}
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="businessName">
                Business Name *
              </label>
              <input
                style={fieldStyles.businessName}
                onBlur={handleBlur}
                type="text"
                id="businessName"
                name="businessName"
                value={formData.businessName}
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>

            {/* DBA Field */}
            {/* <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="dba">
                DBA (Doing Business As)
              </label>
              <input
                style={fieldStyles.dba}
                onBlur={handleBlur}
                type="text"
                id="dba"
                name="dba"
                value={formData.dba}
                onChange={handleChange}
                className="themed-input"
              />
            </div> */}
          </div>

          {/* Address Field */}
          <div className="mb-4 w-full">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="address">
              Company Address *
            </label>
            <input
              style={fieldStyles.address}
              onBlur={handleBlur}
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="themed-input"
              required
            />
          </div>

          {/* City, State, Zip Code Fields */}
          <div className="grid grid-cols-3 gap-0 md:gap-4 lg:gap-4 mb-4">
            <input
              style={fieldStyles.city}
              onBlur={handleBlur}
              type="text"
              placeholder="City *"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              className="input input-bordered w-full col-span-1 themed-input"
              required
            />
            <select
              style={fieldStyles.state}
              onBlur={handleBlur}
              id="state"
              name="state"
              value={formData.state}
              onChange={handleChange}
              className="input input-bordered w-full col-span-1 themed-input cursor-pointer"
              required
            >
              <option value="">State *</option>
								<option value="AL">AL</option>
								<option value="AK">AK</option>
								<option value="AZ">AZ</option>
								<option value="AR">AR</option>
								<option value="CA">CA</option>
								<option value="CO">CO</option>
								<option value="CT">CT</option>
								<option value="DE">DE</option>
								<option value="FL">FL</option>
								<option value="GA">GA</option>
								<option value="HI">HI</option>
								<option value="ID">ID</option>
								<option value="IL">IL</option>
								<option value="IN">IN</option>
								<option value="IA">IA</option>
								<option value="KS">KS</option>
								<option value="KY">KY</option>
								<option value="LA">LA</option>
								<option value="ME">ME</option>
								<option value="MD">MD</option>
								<option value="MA">MA</option>
								<option value="MI">MI</option>
								<option value="MN">MN</option>
								<option value="MS">MS</option>
								<option value="MO">MO</option>
								<option value="MT">MT</option>
								<option value="NE">NE</option>
								<option value="NV">NV</option>
								<option value="NH">NH</option>
								<option value="NJ">NJ</option>
								<option value="NM">NM</option>
								<option value="NY">NY</option>
								<option value="NC">NC</option>
								<option value="ND">ND</option>
								<option value="OH">OH</option>
								<option value="OK">OK</option>
								<option value="OR">OR</option>
								<option value="PA">PA</option>
								<option value="RI">RI</option>
								<option value="SC">SC</option>
								<option value="SD">SD</option>
								<option value="TN">TN</option>
								<option value="TX">TX</option>
								<option value="UT">UT</option>
								<option value="VT">VT</option>
								<option value="VA">VA</option>
								<option value="WA">WA</option>
								<option value="WV">WV</option>
								<option value="WI">WI</option>
								<option value="WY">WY</option>
            </select>
            <input
              style={fieldStyles.zip}
              onBlur={handleBlur}
              maxLength={5}
              type="text"
              placeholder="Zip Code *"
              id="zip"
              name="zip"
              value={formData.zip}
              onChange={handleChange}
              className="input input-bordered w-full col-span-1 themed-input"
              required
            />
          </div>

          <div className='flex items-center flex-col md:flex-row lg:flex-row w-full gap-0 md:gap-4 lg:gap-4'>
            {/* Telephone Field */}
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="tel">
                Telephone *
              </label>
              <input
                style={fieldStyles.tel}
                onBlur={handleBlur}
                type="text"
                maxLength={10}
                id="tel"
                name="tel"
                value={formData.tel}
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>

            {/* Date Incorporated Field */}
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="dateIncorporated">
                Date Incorporated *
              </label>
              <input
                style={fieldStyles.dateIncorporated}
                onBlur={handleBlur}
                type="date"
                id="dateIncorporated"
                name="dateIncorporated"
                value={formData.dateIncorporated}
                onChange={handleChange}
                className="themed-input"
              />
            </div>
          </div>

          {/* Business Category Select */}
          <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="businessCategory">
            Business Description *
          </label>
          <textarea
            maxLength={250}
            style={fieldStyles.businessDescription}
            onBlur={handleBlur}
            id="businessDescription"
            name="businessDescription"
            value={formData.businessDescription}
            onChange={handleChange}
            className="themed-input"
          />
        </div>

          {/* Use of Funds Field */}
          <div className="mb-4 w-full">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="address">
            Use of Funds *
            </label>
            <input
              style={fieldStyles.useOfFunds}
              onBlur={handleBlur}
              type="text"
              id="useOfFunds"
              name="useOfFunds"
              value={formData.useOfFunds}
              onChange={handleChange}
              className="themed-input"
              required
            />
          </div>

        <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
          {/* Industry Select */}
          <div className="mb-4 w-full">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="industry">
              Industry *
            </label>
            <select
              style={fieldStyles.industry}
              onBlur={handleBlur}
              id="industry"
              name="industry"
              value={formData.industry}
              onChange={handleChange}
              className="themed-input cursor-pointer"
            >
              <option value="">Select Industry</option>
              {Object.keys(industryCategories).map((ind) => (
                <option key={ind} value={ind}>{ind}</option>
              ))}
            </select>
          </div>

          {/* Business Owner Telephone Field */}
          <div className="mb-4 w-full">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="ownerTel">
              Business Owner Telephone *
            </label>
            <input
              style={fieldStyles.ownerTel}
              onBlur={handleBlur}
              type="text"
              maxLength={10}
              id="ownerTel"
              name="ownerTel"
              value={formData.ownerTel}
              onChange={handleChange}
              className="themed-input"
              required
            />
          </div>
        </div>

        <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>

          {/* Business Owner Name Field */}
          <div className="mb-4 w-full">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="ownerName">
              Business Owner Name *
            </label>
            <input
              style={fieldStyles.ownerName}
              onBlur={handleBlur}
              type="text"
              id="ownerName"
              name="ownerName"
              value={formData.ownerName}
              onChange={handleChange}
              className="themed-input"
              required
              />
          </div>
          
          {/* Business Owner Email Field */}
          <div className="mb-4 w-full">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="ownerEmail">
              Business Owner Email *
            </label>
            <input
              style={fieldStyles.ownerEmail}
              onBlur={handleBlur}
              type="email"
              id="ownerEmail"
              name="ownerEmail"
              value={formData.ownerEmail}
              onChange={handleChange}
              className="themed-input"
              required
            />
          </div>
      </div>

      <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
        {/* Business Owner Date of Birth Field */}
        <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="ownerDOB">
            Business Owner DOB *
          </label>
          <input
            style={fieldStyles.ownerDOB}
            onBlur={handleBlur}
            type="date"
            id="ownerDOB"
            name="ownerDOB"
            value={formData.ownerDOB}
            onChange={handleChange}
            className="themed-input"
          />
        </div>
    
        {/* Business Owner SSN Field */}
        <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="ownerSSN">
            Business Owner SSN *
          </label>
          <input
            style={fieldStyles.ownerSSN}
            onBlur={handleBlur}
            type="text"
            maxLength={9}
            id="ownerSSN"
            name="ownerSSN"
            value={formData.ownerSSN}
            onChange={handleChange}
            className="themed-input"
            required
          />
        </div>
    
        {/* Percentage Ownership Field */}
        <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="pctOwnership">
            Percentage Ownership *
          </label>
          <select
            style={fieldStyles.pctOwnership}
            onBlur={handleBlur}
            id="pctOwnership"
            name="pctOwnership"
            value={formData.pctOwnership}
            onChange={handleChange}
            className="themed-input cursor-pointer"
            required
          >
            <option value="">Select Percentage</option>
            <option value="10">10%</option>
            <option value="20">20%</option>
            <option value="30">30%</option>
            <option value="40">40%</option>
            <option value="50">50%</option>
            <option value="60">60%</option>
            <option value="70">70%</option>
            <option value="80">80%</option>
            <option value="90">90%</option>
            <option value="100">100%</option>
          </select>
        </div>
      </div>

      <div className="mt-4 mb-4 w-full">
        <label className="block text-grey-darker text-sm font-bold mb-2">
          Does applicant have existing lender balances over $50,000?
        </label>
        <div className="flex items-center space-x-4">
          <label className="flex items-center space-x-2 cursor-pointer">
            <input
              type="radio"
              name="hasLargeLenderBalances"
              value="yes"
              checked={formData.hasLargeLenderBalances === 'yes'}
              onChange={handleChange}
              className='h-[50px] w-[20px] cursor-pointer'
            />
            <span className="text-[14px] font-semibold">Yes</span>
          </label>
          <label className="flex items-center space-x-2 cursor-pointer">
            <input
              type="radio"
              name="hasLargeLenderBalances"
              value="no"
              checked={formData.hasLargeLenderBalances === 'no'}
              onChange={handleChange}
              className='h-[50px] w-[20px] cursor-pointer'
            />
            <span className="text-[14px] font-semibold">No</span>
          </label>
        </div>
      </div>
      <p className='font-medium mb-4'>You can re-upload the files below if required:</p>
      <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
        {/* Bank Statement */}
        <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="dlFront">
            Bank Statement 1 *
          </label>
          {formData.bankStatement1 && (
            <a href={formData.bankStatement1} target="_blank" rel="noopener noreferrer" className="underline">
              View Existing
            </a>
          )}
          <input
            style={fieldStyles.bankStatement1}
            onBlur={handleBlur}
            type="file"
            id="bankStatement1"
            name="bankStatement1"
            onChange={handleChange}
            className="themed-input"
          />
        </div>
        <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="dlFront">
            Bank Statement 2 *
          </label>
          {formData.bankStatement2 && (
            <a href={formData.bankStatement2} target="_blank" rel="noopener noreferrer" className="underline">
              View Existing
            </a>
          )}
          <input
            style={fieldStyles.bankStatement2}
            onBlur={handleBlur}
            type="file"
            id="bankStatement2"
            name="bankStatement2"
            onChange={handleChange}
            className="themed-input"
          />
        </div>
      </div>

      <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
        <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="dlFront">
            Bank Statement 3 *
          </label>
          {formData.bankStatement3 && (
            <a href={formData.bankStatement3} target="_blank" rel="noopener noreferrer" className="underline">
              View Existing
            </a>
          )}
          <input
            style={fieldStyles.bankStatement3}
            onBlur={handleBlur}
            type="file"
            id="bankStatement3"
            name="bankStatement3"
            onChange={handleChange}
            className="themed-input"
          />
        </div>

        {/* Drivers License */}
        <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="dlFront">
            Driver's License Front *
          </label>
          {formData.driversLicenseFront && (
            <a href={formData.driversLicenseFront} target="_blank" rel="noopener noreferrer" className="underline">
              View Existing
            </a>
          )}
          <input
            style={fieldStyles.driversLicenseFront}
            onBlur={handleBlur}
            type="file"
            id="driversLicenseFront"
            name="driversLicenseFront"
            onChange={handleChange}
            className="themed-input"
          />
        </div>
      </div>

      <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
        {/* Voided Check Upload */}
        <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="voidedCheck">
            W-9 Form (optional)
          </label>
          {formData.voidedCheck && (
            <a href={formData.voidedCheck} target="_blank" rel="noopener noreferrer" className="underline">
              View Existing
            </a>
          )}
          <input
            style={fieldStyles.voidedCheck}
            onBlur={handleBlur}
            type="file"
            id="voidedCheck"
            name="voidedCheck"
            onChange={handleChange}
            className="themed-input"
          />
        </div>

        {/* Business Application Upload */}
        <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="businessApplication">
            Business Application *
          </label>
          {formData.businessApplication && (
            <a href={formData.businessApplication} target="_blank" rel="noopener noreferrer" className="underline">
              View Existing
            </a>
          )}
          <input
            style={fieldStyles.businessApplication}
            onBlur={handleBlur}
            type="file"
            id="businessApplication"
            name="businessApplication"
            onChange={handleChange}
            className="themed-input"
          />
        </div>
      </div>

      <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
        {/* Tax Return 1 Upload */}
        <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="taxReturn1">
            Tax Return 1 (optional)
          </label>
          {formData.taxReturn1 && (
            <a href={formData.taxReturn1} target="_blank" rel="noopener noreferrer" className="underline">
              View Existing
            </a>
          )}
          <input
            style={fieldStyles.taxReturn1}
            onBlur={handleBlur}
            type="file"
            id="taxReturn1"
            name="taxReturn1"
            onChange={handleChange}
            className="themed-input"
          />
        </div>

        {/* Tax Return 2 Upload */}
        <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="taxReturn2">
            Tax Return 2 (optional)
          </label>
          {formData.taxReturn2 && (
            <a href={formData.taxReturn2} target="_blank" rel="noopener noreferrer" className="underline">
              View Existing
            </a>
          )}
          <input
            style={fieldStyles.taxReturn2}
            onBlur={handleBlur}
            type="file"
            id="taxReturn2"
            name="taxReturn2"
            onChange={handleChange}
            className="themed-input"
          />
        </div>
      </div>

      <div className="mb-4 w-full">
        <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="personalTaxReturn1">
          Personal Tax Return 1 (optional)
        </label>
        {formData.personalTaxReturn1 && (
          <a href={formData.personalTaxReturn1} target="_blank" rel="noopener noreferrer" className="underline">
            View Existing
          </a>
        )}
        <input
          style={fieldStyles.personalTaxReturn1}
          onBlur={handleBlur}
          type="file"
          id="personalTaxReturn1"
          name="personalTaxReturn1"
          onChange={handleChange}
          className="themed-input"
        />
      </div>

      {/* Submit Button */}
      <div className="flex items-center justify-between">
        <button
          type="submit"
          className="mt-4 bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 rounded disabled:opacity-[80%] disabled:hover:bg-themeColorPrimary"
          disabled={!areAllFieldsFilled() || loading}
        >
          {!loading ? "Update Deal" : <div className='flex items-center'><div className="button-loader mr-2"></div>Please wait</div>}
        </button>
      </div>
      <p className="mt-4 font-bold text-themeColorPrimary2">{loading && submitStatusMessage}</p>
    </form>
  </div>
</div>
  );
};

export default ModifyDeals;
