import React, { useEffect } from 'react';
import { useLocation } from 'wouter';
import { db } from '../config/firebaseConfig';
import { doc, updateDoc } from 'firebase/firestore';
import axios from 'axios';
import { fetchUserInfo } from '../utils/helpers';

export const TermsAndConditionsPage = ({ user, setUserInfo }) => {
  // eslint-disable-next-line
  const [location, navigate] = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAcceptTerms = async () => {
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      // Fetch the user's IP address
      const response = await axios.get('https://api.ipify.org?format=json');
      const ipAddress = response.data.ip;
  
      try {
        await updateDoc(userRef, {
          agreedToTerms: true,
          ipAddress,
        });
        
        // Refetch user info after updating
        const updatedUserInfo = await fetchUserInfo(user.uid);
        setUserInfo(updatedUserInfo); // Update the state with the latest user info
  
        navigate('/portal');
      } catch (error) {
        console.error('Error updating terms acceptance:', error);
      }
    } else {
      console.error('No user is logged in.');
    }
  };

  return (
    <div className="mt-[150px] min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-4xl mx-auto">
        <h1 className="text-2xl font-semibold mb-4 text-center">Terms and Conditions</h1>
        <div className="text-gray-700">
          <h2 className="text-md font-semibold mt-4">1. Purpose</h2>
          <p className="text-[12px] mb-2">
            1.1 Quidity, LLC operates Quidity.net, a referral portal enabling companies to refer their customers to Quidity.Fund for various products.
          </p>
          <p className="text-[12px] mb-2">
            1.2 The Referrer intends to participate in the referral program by referring its customers ("Referred Customers") to Quidity.Fund through Quidity.net. By executing this Agreement, the Referrer confirms its consent and acknowledgment of all terms and conditions herein.
          </p>
          
          <h2 className="text-md font-semibold mt-4">2. Referral Process</h2>
          <p className="text-[12px] mb-2">
            2.1 The Referrer will submit referrals of potential customers for Quidity.Fund's products via the Quidity.net portal, providing all required information as specified by Quidity LLC.
          </p>
          <p className="text-[12px] mb-2">
            2.2 Quidity, LLC will process the referrals, forward them to Quidity.Fund for consideration, and manage the referral relationship between the Referrer and Quidity.Fund.
          </p>
          <p className="text-[12px] mb-2">
            2.3 Quidity.Fund retains sole discretion to accept or reject any Referred Customer.
          </p>
          <p className="text-[12px] mb-2">
            2.4 Quidity, LLC will track referral statuses and provide regular reports to the Referrer on referral progress, either weekly or upon any significant updates or events.
          </p>

          <h2 className="text-md font-semibold mt-4">3. Obligations of the Parties</h2>
          <p className="text-[12px] mb-2">
            3.1 The Referrer represents that all prospective referred customers have authorized in writing the submission of their application. Additionally, the Referrer shall ensure accuracy, completeness, and truthfulness of all referrals.
          </p>
          <p className="text-[12px] mb-2">
            3.2 Quidity, LLC shall maintain confidentiality of all customer information submitted via Quidity.net, complying with applicable data privacy laws.
          </p>
          <p className="text-[12px] mb-2">
            3.3 The Referrer agrees not to disclose Quidity's proprietary information without prior written consent.
          </p>
          <p className="text-[12px] mb-2">
            3.4 The Referrer shall not make false or misleading representations about Quidity, LLC or Quidity.Fund's products to potential Referred Customers. The Referrer's representations shall be limited to materials contained in Quidity's referral guidelines.
          </p>
          <p className="text-[12px] mb-2">
            3.5 By executing this Agreement, the Referrer acknowledges compliance with its terms and any other agreements it is subject to.
          </p>
          <p className="text-[12px] mb-2">
            3.6 The Referrer shall not act contrary to the Referred Customer's performance under this Agreement, or any terms incorporated by Quidity.Fund.
          </p>

          <h2 className="text-md font-semibold mt-4">4. Term and Termination</h2>
          <p className="text-[12px] mb-2">
            4.1 This Agreement commences on the execution date and continues until terminated by either party.
          </p>
          <p className="text-[12px] mb-2">
            4.2 Either party may terminate this Agreement by providing 30 days' written notice.
          </p>
          <p className="text-[12px] mb-2">
            4.3 Upon termination, the Referrer agrees to return all Quidity, LLC materials, documents, and copies at its expense.
          </p>

          <h2 className="text-md font-semibold mt-4">5. Intellectual Property</h2>
          <p className="text-[12px] mb-2">
            5.1 Quidity, LLC retains all intellectual property rights related to Quidity.net and its referral process.
          </p>
          <p className="text-[12px] mb-2">
            5.2 The Referrer shall not use Quidity's trademarks, logos, or other intellectual property without prior written consent.
          </p>

          <h2 className="text-md font-semibold mt-4">6. Limitation of Liability</h2>
          <p className="text-[12px] mb-2">
            6.1 The Referrer agrees to indemnify, defend, and hold Quidity, LLC or Quidity.Fund harmless from any losses, liabilities, claims, damages, and expenses arising from the Referrer's negligence or willful misconduct.
          </p>
          <p className="text-[12px] mb-2">
            6.2 Neither party shall be liable for any indirect, incidental, special, or consequential damages under this Agreement.
          </p>
          <p className="text-[12px] mb-2">
            6.3 This Agreement establishes an independent contractor relationship between the Referrer and Quidity LLC, and no other relationship shall be implied or otherwise.
          </p>

          <h2 className="text-md font-semibold mt-4">7. Governing Law and Venue</h2>
          <p className="text-[12px] mb-2">
            7.1 This Agreement is governed by New Jersey law with venue set in Morris County, New Jersey. Disputes shall be mediated before litigation.
          </p>

          <h2 className="text-md font-semibold mt-4">8. Cost and Attorney's Fees</h2>
          <p className="text-[12px] mb-2">
            8.1 The prevailing Party in a dispute shall collect all legal and collection costs incurred in enforcing this Agreement, including reasonable attorney's fees, from the liable Party.
          </p>

          <h2 className="text-md font-semibold mt-4">9. Entire Agreement</h2>
          <p className="text-[12px] mb-2">
            9.1 This Agreement supersedes all prior agreements and understandings, whether written or oral, pertaining to its subject matter.
          </p>

          <h2 className="text-md font-semibold mt-4">10. Severability</h2>
          <p className="text-[12px] mb-2">
            10.1 If any term of this Agreement is deemed unenforceable, all other terms remain fully enforceable to the extent permitted by law.
          </p>

          <h2 className="text-md font-semibold mt-4">11. Non-Waiver</h2>
          <p className="text-[12px] mb-2">
            11.1 No waiver of any term or condition shall be valid unless made in writing and signed by the Party against whom enforcement is sought.
          </p>

          <p className="text-[12px] mb-2 text-center font-semibold">
            Please note that these are minimum stipulation guidelines and meeting these requirements does not guarantee approval. All applications are subject to underwriting and credit approval. Terms and conditions may vary based on individual business qualifications and the lender's discretion.
          </p>

          <p className="text-[12px] mb-2 text-center font-semibold">
            You acknowledge understanding and agreement with the terms of this Agreement.
          </p>
          
          <button className="w-full py-2 bg-themeColorPrimary text-white rounded-lg font-bold hover:bg-themeColorPrimary2 transition duration-200 mt-4" onClick={handleAcceptTerms}>
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};
