import { createUserWithEmailAndPassword } from 'firebase/auth';
import React, { useState } from 'react';
import { auth, db } from '../config/firebaseConfig';
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import axios from 'axios';

import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useLocation } from 'wouter';
import { sendNewRegistrationEmail, sendWelcomeEmail } from '../utils/helpers';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';

const initialStyles = {
  email: {},
  password: {},
  confirmPassword: {},
  companyName: {},
  dba: {},
  address: {},
  city: {},
  state: {},
  zip: {},
  officeNumber: {},
  ein: {},
  primaryContactPerson: {},
  telephone: {},
  contactEmail: {},
  voidedCheck: {},
  driversLicense: {},
};

const validStyle = { outline: '2px solid #078586' };
const invalidStyle = { outline: '2px solid red' };

const RegistrationPage = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    companyName: '',
    dba: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    officeNumber: '',
    ein: '',
    primaryContactPerson: '',
    telephone: '',
    contactEmail: '',
    driversLicense: '',
    voidedCheck: '',
    agreedToTerms: false,
  });

  const [hidePass, setHidePass] = useState(true);

  const [loading, setLoading] = useState(false);
  const [submitStatusMessage, setSubmitStatusMessage] = useState('');
  // eslint-disable-next-line
  const [location, navigate] = useLocation();

  const [errorMessage, setErrorMessage] = useState('');

  const storage = getStorage();

  const getPublicUrl = async (fileRef) => {
    try {
      const fileUrl = await getDownloadURL(ref(storage, fileRef));
      return fileUrl;
    } catch (error) {
      setLoading(false);
      console.error('Error getting public URL:', error);
      setErrorMessage(error.message);
    }
  };

  const uploadFileToFirebase = async (file) => {
    if (!file) return '';
    const storageRef = ref(storage, 'uploads/' + file.name);
    await uploadBytes(storageRef, file);
    return getPublicUrl(storageRef);
  };

  const submitForm = async () => {
    setLoading(true);
    setSubmitStatusMessage('Uploading documents...');

    const driversLicenseUrl = await uploadFileToFirebase(formData.driversLicense);
    const voidedCheckUrl = await uploadFileToFirebase(formData.voidedCheck);

    const HUBSPOT_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/45548688/bc873941-e934-44b4-927a-795be17de045`;

    var name = formData.primaryContactPerson.split(' ');
    const firstName = name[0] || '';
    const lastName = name[1] || '';
    
    const hubspotFormData = {
      fields: [
        {
          name: "company",
          value: formData.companyName,
        },
        {
          name: "address",
          value: formData.address,
        },
        {
          name: "city",
          value: formData.city,
        },
        {
          name: "state",
          value: formData.state,
        },
        {
          name: "zip",
          value: formData.zip,
        },
        {
          name: "phone",
          value: formData.officeNumber,
        },
        {
          name: "email",
          value: formData.email,
        },
        {
          name: "ein",
          value: formData.ein,
        },
        {
          name: "firstname",
          value: firstName,
        },
        {
          name: "lastname",
          value: lastName,
        },
        {
          name: "mobilephone",
          value: formData.telephone,
        },
        {
          name: "contact_email",
          value: formData.email,
        },
        {
          name: "drivers_license",
          value: driversLicenseUrl,
        },
        {
          name: "voided_check",
          value: voidedCheckUrl,
        },
        {
          name: "salutation",
          value: formData.agreedToTerms ? 'yes' : 'no',
        },
      ],
    };

    setSubmitStatusMessage('Submitting records...');

    try {
      await axios.post(HUBSPOT_ENDPOINT, hubspotFormData);
    } catch (error) {
        console.error('Network error:', error);
        setLoading(false);
        setErrorMessage(error.message);
    }

    return { 
      driversLicenseUrl,
      voidedCheckUrl,
    };
  };

  const registerUser = async (
    driversLicenseUrl,
    voidedCheckUrl,
  ) => {
    try {
      const { 
        email, password, primaryContactPerson, companyName, 
        address, city, state, zip, telephone, contactEmail, ...otherFormData 
      } = formData;
  
      if (!email || !password || !primaryContactPerson) {
        setErrorMessage('Email, password, and primary contact person are required.');
        return;
      }
  
      const name = primaryContactPerson.split(' ');
      const firstName = name[0] || '';
      const lastName = name[1] || '';
  
      setSubmitStatusMessage('Creating account...');
      setLoading(true);
  
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        
        if (!userCredential.user) {
          throw new Error('Failed to create user.');
        }
  
        const userRef = doc(db, 'users', userCredential.user.uid);
        await setDoc(userRef, {
          userId: userCredential.user.email,
          uid: userCredential.user.uid,
          firstName,
          lastName,
          createdAt: serverTimestamp(),
          ...otherFormData,
          driversLicense: driversLicenseUrl,
          voidedCheck: voidedCheckUrl,
          companyName,
        });
  
        await sendWelcomeEmail(email, `${firstName} ${lastName}`);
        await sendNewRegistrationEmail(companyName, address, city, state, zip, firstName, lastName, telephone, contactEmail);
        
        setSubmitStatusMessage('Done...');
        setLoading(false);
        navigate('/terms');
      } catch (error) {
        console.error('Error creating account:', error.message);
        setErrorMessage('Failed to create account. Please try again.');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error during form submission:', error.message);
      setErrorMessage('Failed to submit form. Please try again.');
      setLoading(false);
    }
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      submitForm()
        .then(async ({
          driversLicenseUrl,
          voidedCheckUrl,
        }) => {
          await registerUser(driversLicenseUrl, voidedCheckUrl);
        });
    }
    catch (error) {
      console.error('Error creating account:', error.message);
      setLoading(false);
      setErrorMessage(error.message);
    }
  };
  
  const areAllFieldsFilled = () => {
    const {
      email, password, confirmPassword, companyName, address, city,
      state, zip, officeNumber, ein, primaryContactPerson, telephone,
      contactEmail, driversLicense, voidedCheck
    } = formData;
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    return (
      emailRegex.test(email) &&
      emailRegex.test(contactEmail) &&
      password.length >= 8 &&
      password === confirmPassword &&
      companyName.trim() !== '' &&
      address.trim() !== '' &&
      city.trim() !== '' &&
      state.trim() !== '' &&
      /^\d{5}$/.test(zip) &&
      /^\d{10}$/.test(officeNumber) &&
      /^\d{9}$/.test(ein) &&
      primaryContactPerson.trim() !== '' &&
      /^\d{10}$/.test(telephone) &&
      driversLicense &&
      voidedCheck
    );
  };
  

  const [fieldStyles, setFieldStyles] = useState(initialStyles);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    handleBlur(e);

    if (name === 'driversLicense' || name === 'voidedCheck') {
      setFormData(prevState => ({
        ...prevState,
        [name]: files[0]
      }));
    } else if (name === 'officeNumber' || name === 'telephone' || name === 'zip') {
      const isIntegerOrEmpty = /^-?\d*$/.test(value);
      if (isIntegerOrEmpty) {
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      }
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    let isValid = false;

    switch (name) {
      case 'contactEmail':
      case 'email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        isValid = emailRegex.test(value);
        break;
      case 'zip':
        isValid = /^\d{5}$/.test(value);
        break;
      case 'officeNumber':
      case 'telephone':
        isValid = /^\d{10}$/.test(value);
        break;
      case 'password':
        isValid = value.length >= 8;
        break;
      case 'confirmPassword':
        isValid = value === formData.password && value !== '';
        break;
      case 'ein':
        isValid = /^\d{9}$/.test(value);
        break;
      case 'voidedCheck':
        isValid = value != null && value !== '';
        break;
      case 'companyName':
      case 'address':
      case 'city':
      case 'state':
      case 'primaryContactPerson':
        isValid = value.trim() !== '';
        break;
      default:
        isValid = true;
    }

    setFieldStyles(prevStyles => ({
      ...prevStyles,
      [name]: isValid ? validStyle : invalidStyle
    }));
  };

  const handleFocus = (e) => {
    const { name } = e.target;
    let updatedStyles = { ...initialStyles };
  
    if (name === 'confirmPassword') {
      updatedStyles.confirmPassword = formData.confirmPassword === formData.password ? validStyle : invalidStyle;
    } else if (name === 'email' || name === 'contactEmail') {
      updatedStyles[name] = validateEmail(formData[name]) ? validStyle : invalidStyle;
    } else {
      updatedStyles[name] = formData[name].trim() !== '' ? validStyle : invalidStyle;
    }
  
    setFieldStyles(prevStyles => ({
      ...prevStyles,
      ...updatedStyles,
    }));
  };
  

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  return (
    <div className="bg-themeColorPrimary3 min-h-screen flex flex-col justify-center items-center">
      <div className="mt-[120px] bg-white shadow-md rounded-xl px-8 pt-6 pb-8 mb-4 w-[95%] md:w-[600px] lg:w-[600px]">
        <h1 className="block w-full text-center text-grey-darker text-[28px] mb-6 font-bold text-themeColorPrimary2">Create an Account</h1>
        <form onSubmit={handleSubmit}>
          {/* Email Field */}
          <div className="mb-4">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="email">
              Email *
            </label>
            <input
              style={fieldStyles.email}
              onBlur={handleBlur}
              onFocus={handleFocus}
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="themed-input"
              required
            />
          </div>

          {/* Password Field */}
          <div className="mb-4">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="password">
              Password *
            </label>
            <div style={fieldStyles.password} className="flex items-center shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker focus-within:outline outline-themeColorPrimary">
              <input
                onBlur={handleBlur}
                onFocus={handleFocus}
                type={hidePass ? "password" : "text"}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="outline-none w-full"
                required
              />
              <div className="cursor-pointer hover:opacity-[80%]" onClick={() => setHidePass(!hidePass)}>
                {
                  hidePass
                  ?
                  <BiSolidShow size={20}/>
                  :
                  <BiSolidHide size={20}/>
                }
              </div>
            </div>
          </div>

          {/* Confirm Password Field */}
          <div className="mb-4">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="confirmPassword">
              Confirm Password *
            </label>
            <div style={fieldStyles.confirmPassword} className="flex items-center shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker focus-within:outline outline-themeColorPrimary">
              <input
                onBlur={handleBlur}
                onFocus={handleFocus}
                type={hidePass ? "password" : "text"}
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="outline-none w-full"
                required
              />
            </div>
          </div>

          {/* Company Name Field */}
          <div className="mb-4">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="companyName">
              Company Name *
            </label>
            <input
              style={fieldStyles.companyName}
              onBlur={handleBlur}
              onFocus={handleFocus}
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              className="themed-input"
              required
            />
          </div>

        {/* DBA Field */}
        {/* <div className="mb-4">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="dba">
            DBA (Doing Business As)
          </label>
          <input
            style={fieldStyles.dba}
            onBlur={handleBlur}
            onFocus={handleFocus}
            type="text"
            id="dba"
            name="dba"
            value={formData.dba}
            onChange={handleChange}
            className="themed-input"
          />
        </div> */}

        {/* Address Field */}
        <div className="mb-4">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="address">
            Address *
          </label>
          <input
            style={fieldStyles.address}
            onBlur={handleBlur}
            onFocus={handleFocus}
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="themed-input"
            required
          />
        </div>

        {/* City Field */}
        <div className="mb-4">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="city">
            City *
          </label>
          <input
            style={fieldStyles.city}
            onBlur={handleBlur}
            onFocus={handleFocus}
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            className="themed-input"
            required
          />
        </div>

        {/* State Field */}
        <div className="mb-4">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="state">
            State *
          </label>
          <select
            style={fieldStyles.state}
            onBlur={handleBlur}
            onFocus={handleFocus}
            id="state"
            name="state"
            value={formData.state}
            onChange={handleChange}
            className="input input-bordered w-full col-span-1 themed-input cursor-pointer"
            required
          >
            <option value="">State</option>
              <option value="AL">AL</option>
              <option value="AK">AK</option>
              <option value="AZ">AZ</option>
              <option value="AR">AR</option>
              <option value="CA">CA</option>
              <option value="CO">CO</option>
              <option value="CT">CT</option>
              <option value="DE">DE</option>
              <option value="FL">FL</option>
              <option value="GA">GA</option>
              <option value="HI">HI</option>
              <option value="ID">ID</option>
              <option value="IL">IL</option>
              <option value="IN">IN</option>
              <option value="IA">IA</option>
              <option value="KS">KS</option>
              <option value="KY">KY</option>
              <option value="LA">LA</option>
              <option value="ME">ME</option>
              <option value="MD">MD</option>
              <option value="MA">MA</option>
              <option value="MI">MI</option>
              <option value="MN">MN</option>
              <option value="MS">MS</option>
              <option value="MO">MO</option>
              <option value="MT">MT</option>
              <option value="NE">NE</option>
              <option value="NV">NV</option>
              <option value="NH">NH</option>
              <option value="NJ">NJ</option>
              <option value="NM">NM</option>
              <option value="NY">NY</option>
              <option value="NC">NC</option>
              <option value="ND">ND</option>
              <option value="OH">OH</option>
              <option value="OK">OK</option>
              <option value="OR">OR</option>
              <option value="PA">PA</option>
              <option value="RI">RI</option>
              <option value="SC">SC</option>
              <option value="SD">SD</option>
              <option value="TN">TN</option>
              <option value="TX">TX</option>
              <option value="UT">UT</option>
              <option value="VT">VT</option>
              <option value="VA">VA</option>
              <option value="WA">WA</option>
              <option value="WV">WV</option>
              <option value="WI">WI</option>
              <option value="WY">WY</option>
          </select>
        </div>

        {/* Zip Field */}
        <div className="mb-4">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="zip">
            Zip *
          </label>
          <input
            maxLength={5}
            style={fieldStyles.zip}
            onBlur={handleBlur}
            onFocus={handleFocus}
            type="text"
            id="zip"
            name="zip"
            value={formData.zip}
            onChange={handleChange}
            className="themed-input"
            required
          />
        </div>

        {/* Office Number Field */}
        <div className="mb-4">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="officeNumber">
            Office Number *
          </label>
          <input
            style={fieldStyles.officeNumber}
            onBlur={handleBlur}
            onFocus={handleFocus}
            type="text"
            maxLength={10}
            id="officeNumber"
            name="officeNumber"
            value={formData.officeNumber}
            onChange={handleChange}
            className="themed-input"
            required
          />
        </div>

        {/* EIN Field */}
        <div className="mb-4">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="ein">
            EIN (Employer Identification Number) *
          </label>
          <input
            maxLength={9}
            style={fieldStyles.ein}
            onBlur={handleBlur}
            onFocus={handleFocus}
            type="text"
            id="ein"
            name="ein"
            value={formData.ein}
            onChange={handleChange}
            className="themed-input"
            required
          />
        </div>

        {/* Primary Contact Person Field */}
        <div className="mb-4">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="primaryContactPerson">
            Primary Contact Person *
          </label>
          <input
            style={fieldStyles.primaryContactPerson}
            onBlur={handleBlur}
            onFocus={handleFocus}
            type="text"
            id="primaryContactPerson"
            name="primaryContactPerson"
            value={formData.primaryContactPerson}
            onChange={handleChange}
            className="themed-input"
            required
          />
        </div>

        {/* Telephone Field */}
        <div className="mb-4">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="telephone">
            Telephone *
          </label>
          <input
            style={fieldStyles.telephone}
            onBlur={handleBlur}
            onFocus={handleFocus}
            type="text"
            maxLength={10}
            id="telephone"
            name="telephone"
            value={formData.telephone}
            onChange={handleChange}
            className="themed-input"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="contactEmail">
            Contact Email *
          </label>
          <input
            style={fieldStyles.contactEmail}
            onBlur={handleBlur}
            onFocus={handleFocus}
            type="email"
            id="contactEmail"
            name="contactEmail"
            value={formData.contactEmail}
            onChange={handleChange}
            className="themed-input"
            required
          />
        </div>

        {/* Drivers License */}
        <div className="mb-4">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="driversLicense">
            Driver's License *
          </label>
          <input
            style={fieldStyles.driversLicense}
            onBlur={handleBlur}
            onFocus={handleFocus}
            type="file"
            id="driversLicense"
            name="driversLicense"
            onChange={handleChange}
            className="themed-input"
          />
        </div>
        
        {/* Voided Check Upload */}
        <div className="mb-4">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="voidedCheck">
            W-9 Form *
          </label>
          <input
            style={fieldStyles.voidedCheck}
            onBlur={handleBlur}
            onFocus={handleFocus}
            type="file"
            id="voidedCheck"
            name="voidedCheck"
            onChange={handleChange}
            className="themed-input"
            required
          />
        </div>

        {/* Submit Button */}
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="mt-4 bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 rounded disabled:opacity-[50%] disabled:hover:bg- disabled:bg-gray-600"
            disabled={!areAllFieldsFilled() || loading}
          >
            {!loading ? "Create Account" : <div className='flex items-center'><div className="button-loader mr-2"></div>Creating Account</div>}
          </button>
        </div>
        <p className="mt-4 font-bold text-themeColorPrimary2">{loading && submitStatusMessage}</p>
      </form>
      <p className='mt-4 font-bold text-red-500'>
        {errorMessage}
      </p>
    </div>
  </div>
);
};

export default RegistrationPage;
